

/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
    
import PageMain from '@part/layout/PageMain.vue';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        data() {
            return {
                checkboxModel: ['item1'],
                tabsModel: 1,
                textModel: '',
                telModel: '',
                emailModel: '',
                urlModel: '',
                numberModel: '',
                timeModel: '',
                dateModel: '',
                datetimeLocalModel: '',
                weekModel: '',
                monthModel: '',
                passwordModel: '',
            };
        },

        components: {
            PageMain
        },

        methods: {
            buttonClicked(e) {
                console.log('EVENT', e);
            }
        }
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class Dev extends Vue {}

